import React, { useState } from "react";
import styles from "./cxTile.scss";
import * as utils from '../../utils/utils';

function CXTile(props){
    const { cxScore } = props;
    console.log("CXTile---CXScore",cxScore);
    return (
        <div
            className={styles.cardContainer}        >
            {<div className={styles.cardHeader}>
                <div className={styles.cardTitle}>CX Score</div>
                {/* (cxScore.dataPoints !== undefined)?
                <div className={styles.nValue}><span className={styles.tooltipForCX}>Total number of order lines considered for calculation of overall CX Score in this quarter</span>n = { utils.convertToInternationalCurrencySystem(cxScore.dataPoints)}</div>:''
    */}
            </div>}
            {/*<div className={styles.cxCardBody}>
                <div className={styles.progressVal}>
                    {
                        !cxScore.score ? `-` : <span>{Number(cxScore.score).toFixed(0)}/100</span>
                    }
                </div>
                <div className={styles.myProgress}>
                    <div className={cxScore.score >= cxScore.benchmark ? `${styles.myBar} bg-green` : `${styles.myBar} bg-red`} style={{ width: `${cxScore.score}%` }}></div>
                </div>
                {
                    Number(cxScore.score).toFixed(0) === 0 ? <span className={styles.indexDesc}>No transactions in current quarter</span> :
                        Number(cxScore.score).toFixed(0) === Number(cxScore.lastQuarterValue).toFixed(0) ? <div className={styles.indexDesc}>
                            <span>Same as last quarter</span>
                            <span className={styles.arrowNoChange}></span>
                        </div>
                            :
                            <div className={styles.indexDesc}>
                                <span className={cxScore.score > cxScore.lastQuarterValue ? 'text-color-green' : 'text-color-red'}>{Math.abs(Number(cxScore.score).toFixed(0) - Number(cxScore.lastQuarterValue).toFixed(0))}</span> Points
                                <span className={cxScore.score > cxScore.lastQuarterValue ? styles.arrowUp : styles.arrowDown}></span>from last quarter
                            </div>
                }
            </div>*/}
        </div>
    )


}
export default CXTile;
import React, { useState, useEffect } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";
import { Spin } from 'antd';

function CustomerAttribute(props) {

    const { divisionCode } = props
    const { code } = props
    const { whiteGlove } = props
    const [productDivisions, setProductDivisions] = useState(null)
    const [ofm, setOFM] = useState(null)//Add Revenue Distribution by order fulfillment method
    //const [regions, setRegions] = useState(null)
    //const [custSegment, setCustSegment] = useState(null)
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [AR, setAR] = useState(null)
    const [credit, setCredit] = useState(null)
    const [sgns, setSgns] = useState(null)
    const [customerDetails, setCustomerDetails] = useState(null)
    const [loading, setIsLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- 
    const [nsgns, setNsgns] = useState(null) //addded for Included Accounts accordion
    console.log("In Attributes file--code is", code)
    //console.log("Customer Details in Attributes file",customerDetails);
    const processData = (customerDetails) => {

        const sgns = [...customerDetails.revenueDistribution.sgns]
        setSgns([...sgns.sort(utils.sorting('revenue', "number", false))])
        //Removed Revenue distribution by Region
        /*if (customerDetails.revenueDistribution && customerDetails.revenueDistribution.regions &&
            customerDetails.revenueDistribution.regions.length && Object.keys(customerDetails.revenueDistribution.regions[0]).length) {
            const regions = [...customerDetails.revenueDistribution.regions]
            setRegions([...regions.sort(utils.sorting('revenue', "number", false))])
        }*/
        //addded for Included Accounts accordion
        if (customerDetails && customerDetails.revenueDistribution && customerDetails.revenueDistribution.nsgn &&
            customerDetails.revenueDistribution.nsgn.length && Object.keys(customerDetails.revenueDistribution.nsgn[0]).length) {
            const nsgns = [...customerDetails.revenueDistribution.nsgn]
            setNsgns([...nsgns.sort(utils.sorting("name", "string", true))])
        }
        if (customerDetails && customerDetails.revenueDistribution && customerDetails.revenueDistribution.productDivisions &&
            customerDetails.revenueDistribution.productDivisions.length && Object.keys(customerDetails.revenueDistribution.productDivisions[0]).length) {
            const productDivisions = [...customerDetails.revenueDistribution.productDivisions]
            setProductDivisions([...productDivisions.sort(utils.sorting('revenue', "number", false))])
        }
        //Add Revenue Distribution by order fulfillment method
        if (customerDetails && customerDetails.revenueDistribution && customerDetails.revenueDistribution.ofm &&
            customerDetails.revenueDistribution.ofm.length && Object.keys(customerDetails.revenueDistribution.ofm[0]).length) {
            const ofms = [...customerDetails.revenueDistribution.ofm]
            setOFM([...ofms.sort(utils.sorting('revenue', "number", false))])
        }
        /* const obj = {
             segment: customerDetails.segment,
             segmentGroup: customerDetails.segmentGroup //Removed Cusotmer segment group
         }*/
        // setCustSegment({ ...obj })

        const credit = {
            total: customerDetails.totalCreditLimit,
            available: customerDetails.availableCredit
        }
        setCredit({ ...credit })

        const open_backorder = {
            open: customerDetails.openorder,
            back: customerDetails.backorder
        }
        setOpen_Backorder(open_backorder)

        const open_dueAR = {
            open: customerDetails.open_ar_amount,
            due: customerDetails.past_due_ar_amount
        }
        setAR(open_dueAR)

        return customerDetails
    };
    const formatESData = (result) => {
        if (utils.isEmpty(divisionCode)) {
            let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : []
            let group = (hitsArray && hitsArray.inner_hits && hitsArray.inner_hits.customer && hitsArray.inner_hits.customer.hits && hitsArray.inner_hits.customer.hits.hits && hitsArray.inner_hits.customer.hits.hits.length > 0) ? hitsArray.inner_hits.customer.hits.hits[0] : [];
            return (group && group['_source']) ? group['_source'] : {};
        } else {
            let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : []
            const productDivisionCustomer = (hitsArray && hitsArray.inner_hits && hitsArray.inner_hits['productDivision.customers'] && hitsArray.inner_hits['productDivision.customers'].hits && hitsArray.inner_hits['productDivision.customers'].hits.hits && hitsArray.inner_hits['productDivision.customers'].hits.hits.length > 0) ? hitsArray.inner_hits['productDivision.customers'].hits.hits[0] : [];
            return (productDivisionCustomer && productDivisionCustomer['_source']) ? productDivisionCustomer['_source'] : {};
        }
    }
    useEffect(() => {
        let params;
        if (utils.isEmpty(divisionCode)) {
            params = {
                "_source": false,
                "query": {
                    "nested": {
                        "path": "customer",
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "match": {
                                            "customer.company_region": region
                                        }
                                    },
                                    {
                                        "match": {
                                            "customer.code": code
                                        }
                                    }
                                ]
                            }
                        },
                        "inner_hits": {
                            "size": 1
                        }
                    }
                }
            }
        } else {
            params = {
                "_source": {
                    "excludes": "*.customers"
                },
                "query": {
                    "nested": {
                        "path": "productDivision.customers",
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "match": {
                                            "productDivision.customers.code": code
                                        }
                                    },
                                    {
                                        "match": {
                                            "productDivision.customers.productdivision": divisionCode
                                        }
                                    },
                                    {
                                        "match": {
                                            "productDivision.customers.company_region": region
                                        }
                                    }
                                ]
                            }
                        },
                        "inner_hits": {
                            "size": 1
                        }
                    }
                }
            }
        }
        setIsLoading(true)
        const input = {
            name: utils.isEmpty(divisionCode) ? constants.Customer_Attributes_Index : constants.PD_Customer_Attributes_Index,
            queryParams: params
        }
        //EMEA Release
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let ESData = formatESData(resp.data);
                console.log("Atrribute ES response formatted", ESData)
                let customerDetails;
                if (!props.divisionCode) {
                    customerDetails = ESData;
                } else {
                    customerDetails = ESData;
                }
                if (customerDetails) {
                    let _customerDetails = processData(customerDetails)
                    let custSearchCode = code.split(" ").join("").toLowerCase();
                    let custRespCode = _customerDetails.code.split(" ").join("").toLowerCase();
                    console.log("Values to be matched cust Search code --- ", custSearchCode, "----Cust Resp code", custRespCode)
                    if (custSearchCode === custRespCode) {
                        setCustomerDetails(_customerDetails)
                        props.isattrSectionLoaded(true);
                    }
                    else {
                        setCustomerDetails(null)
                        props.isattrSectionLoaded(false);
                    }

                } else {
                    setCustomerDetails(null)
                    props.isattrSectionLoaded(false);
                }
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }, [code, divisionCode])

    return (
customerDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>
                <div>
                    {`${utils.capitalize(props.code)} - ${region}`}
                    <span>
                        {
                            divisionCode ? ' (Division Level View)' : ' (Account Level View)'
                        }
                    </span>
                </div>
                <div className={whiteGlove === "true" ? styles.whiteGlove : null}></div>
            </div>
            <div className={styles.attributesBody}>
                {/* <div className={`${styles.groupTopSection}`}>
                        <div className={styles.innerLayout}>
                            <div className={styles.bodyTitle}>YTD Revenue​</div>
                            <div className={styles.bodyValue}>{(customerDetails.totalRevenue) ? '$' + utils.convertToInternationalCurrencySystem(customerDetails.totalRevenue.toFixed(2)) : '-'}</div>
                        </div>
                        <div className={styles.innerLayout}>
                            <div className={styles.bodyTitle}>YTD Growth​</div>
                            <div className={styles.bodyValue}>{(customerDetails.YTDGrowth) ? customerDetails.YTDGrowth.toFixed(2) + '%' : '-'}</div>
                        </div>
                        <div className={styles.innerLayout}>
                            <div className={styles.bodyTitle}>QoQ Growth​</div>
                            <div className={styles.bodyValue}>{(customerDetails.QoQGrowth) ? customerDetails.QoQGrowth.toFixed(2) + '%' : '-'}</div>
                        </div>
                    </div> */}
                {//Remove Total YTD Revenue, Total YoY Growth, and Total QoQ Growth boxes
                /*<div className={`${styles.groupTopSection} ${styles.borderBottom}`}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(customerDetails.globaltotalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(customerDetails.globaltotalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YoY Growth​</div>
                        <div className={styles.bodyValue}>{(customerDetails.globalYTDGrowth !== undefined) ? customerDetails.globalYTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(customerDetails.globalQoQGrowth !== undefined) ? customerDetails.globalQoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>*/}

                {/*<div className={styles.segmentSection}>*/}
                {//Removed Customer Segment Group
                    /*<div className={styles.segment}>
                        <div className={styles.bodyTitle}>Customer Segment Group​</div>
                        <div className={styles.bodyValue}>{custSegment.segmentGroup ? custSegment.segmentGroup : '-'}</div>
                     </div>*/}
                {/*<div className={styles.segment}>
                        <div className={styles.bodyTitle}>Customer Segment​</div>
                        <div className={styles.bodyValue}>
                            {(custSegment.segment) ? custSegment.segment.split(';').map((val, i) =>
                                <div key={i}>{val}</div>
                            ) : '-'
                            }
                        </div>
                        </div>*/}
                {/*</div> */}
                {/* </div> */}
                <div>
                    {
                        <Accordion
                            title="Included NSGN Accounts"
                            type="nsgn"
                            list={nsgns}
                        />
                    }
                    {
                        <Accordion
                            title="Top 5 SGNs by Revenue​ ($M)"
                            type="sgns"
                            sgns={sgns}
                            voiceRevenue={customerDetails.totalRevenue}
                        />
                    }
                    {
                        <div className={styles.groupTopSection}>
                            <div className={styles.innerLayout}>
                                <div className={styles.bodyTitle}>VOICE YTD Revenue​</div>
                                <div className={styles.bodyValue}>{(customerDetails.totalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(customerDetails.totalRevenue.toFixed(2)) : '-'}</div>
                            </div>
                            <div className={styles.innerLayout}>
                                <div className={styles.bodyTitle}>VOICE YoY Growth​</div>
                                <div className={styles.bodyValue}>{(customerDetails.YTDGrowth !== undefined) ? customerDetails.YTDGrowth.toFixed(2) + '%' : '-'}</div>
                            </div>
                            <div className={styles.innerLayout}>
                                <div className={styles.bodyTitle}>VOICE QoQ Growth​</div>
                                <div className={styles.bodyValue}>{(customerDetails.QoQGrowth !== undefined) ? customerDetails.QoQGrowth.toFixed(2) + '%' : '-'}</div>
                            </div>
                        </div>
                    }
                    {
                        <Accordion
                            title="Revenue Distribution by OFM (YTD)"
                            type="OFM"
                            list={ofm}
                        />
                    }
                    {
                        !divisionCode ? <Accordion
                            title="Revenue Distribution by Product Divisions (YTD)"
                            type="productDivision"
                            list={productDivisions}
                        /> : null
                    }
                    {/*<Accordion
                        title="Revenue Distribution by Region (YTD)"
                        type="regions"
                        list={regions}
                    />*/}
                    {<Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                        customerDetails={customerDetails}
                    />}
                    {<Accordion title="Accounts Receivable"
                        type="accountReceivable"
                        open_dueAR={AR}
                        customerDetails={customerDetails}
                    />}
                    {<Accordion title="Credit Limit"
                        type="credit"
                        credit={credit}
                    />}
                </div>
            </div>
        </div>
        )
}

export default CustomerAttribute